import { useQuery } from '@apollo/client'
import { Card, Col, Row } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'

import { IndexPageDocument } from '../../../generated/graphql'
import {
  add1second,
  DateRange,
  formatDate,
  getDateRange,
} from '../../../utils/date'
import { PageContainer } from '../../layouts'
import { ProductSalesSummary } from '../../ProductSalesSummary'
import { Error500Template } from '../../templates/Error500Template'
import { ProductSalesModel } from './models'

export const IndexPage: React.FC = () => {
  const router = useRouter()

  const thisMonth = getDateRange(new Date(), 'month')
  const today = getDateRange(new Date(), 'day')
  const { loading, error, data } = useQuery(IndexPageDocument, {
    variables: {
      thisMonthFrom: thisMonth.from.toISOString(),
      thisMonthTo: add1second(thisMonth.to).toISOString(),
      todayFrom: today.from.toISOString(),
      todayTo: add1second(today.to).toISOString(),
    },
  })

  if (loading) return <PageContainer loading />
  if (error || !data) return <Error500Template />

  const role = data?.productProviderMember.role

  if (role !== 'owner') {
    router.replace('/simple_pass/my_passes')
    return null
  }

  return (
    <Container
      thisMonthProductSales={{
        dateRange: thisMonth,
        data: data.thisMonthProductSales,
      }}
      todayProductSales={{
        dateRange: today,
        data: data.todayProductSales,
      }}
    />
  )
}

//
// Container
//

type ContainerProps = {
  thisMonthProductSales: {
    dateRange: DateRange
    data: ProductSalesModel
  }
  todayProductSales: {
    dateRange: DateRange
    data: ProductSalesModel
  }
}
const Container: React.FC<ContainerProps> = (props) => <Screen {...props} />

//
// Screen
//

type ScreenProps = ContainerProps
const Screen: React.FC<ScreenProps> = ({
  thisMonthProductSales,
  todayProductSales,
}) => (
  <PageContainer>
    <Row gutter={16}>
      <Col md={24} lg={12}>
        <Card
          title={
            <>
              今月の売上
              <small>
                （
                {formatDate(
                  thisMonthProductSales.dateRange.from,
                  'YYYY年MM月DD日'
                )}
                ~
                {formatDate(
                  thisMonthProductSales.dateRange.to,
                  'YYYY年MM月DD日'
                )}
                ）
              </small>
            </>
          }
        >
          <ProductSalesSummary productSales={thisMonthProductSales.data} />
        </Card>
      </Col>
      <Col md={24} lg={12}>
        <Card
          title={
            <>
              今日の売上
              <small>
                （
                {formatDate(todayProductSales.dateRange.from, 'YYYY年MM月DD日')}
                ）
              </small>
            </>
          }
        >
          <ProductSalesSummary productSales={todayProductSales.data} />
        </Card>
      </Col>
    </Row>
  </PageContainer>
)
